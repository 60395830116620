%base-button {
	font-family: $raleway;
	font-size: rem(14px);
	$color: #fff;
	$bg: #3b3e43;
	padding: .5em 1em;
	color: $color;
	background-color: $bg;
	border-width: 3px;
	border-style: solid;
	letter-spacing: letter-spacing(10);
	font-weight: weight('Black');
	transition: background-color .6s ease;
	box-sizing: border-box;
	min-height: em(47px);
	text-align: center;
	vertical-align: middle;
	display: inline-block;
	&:not(.disabled):not(:disabled) {
		cursor: pointer;
	}
}

%btn-decoration {
	position: relative;
	$offset: -2em;
	&:after,
	&:before {
		position: absolute;
		font-size: 2em;
		top: -.5em;
		font-family: "HadroutIcons";
		font-weight: bold;
		content: icon-char('btn-decoration');
	}
	&:before {
		left: $offset;
	}
	&:after {
		transform: scale(-1, 1);
		right: $offset;
	}
}

.btn {
	@extend %base-button;
	&.white-border {
		border-color: #fff;
		&:hover {
			background-color: #fff;
			color: $primary;
		}
	}
	&.primary-border {
		border-color: $primary;
		&:hover {
			background-color: $primary;
		}
	}
	&.btn-decoration {
		@extend %btn-decoration;
		&.margin-left {
			@include media-breakpoint-up(md) {
				margin-left: 3.5em;
			}
		}
	}
}

.slider-btn  {
	@extend %base-button;
	@extend %btn-decoration;
	$border-color: $primary; 
	border-color: $border-color;
	&:before,
	&:after {
		color: $primary;
	}
	&:hover {
		color: #fff;
		background-color: $border-color;
	}
	
}

.back-btn {
	@extend %base-button;
	background-color: $primary;
	color: #fff;
	position: relative;
	padding-left: 3em;
	padding-right: 3em;

	&:before {
		position: absolute;
		height: 119%;
		width: 3em;
		left: -3.5em;
		top: rem(-3px);
		font-family: $awesome;
		content: $fa-var-arrow-left;
		background-color: #969696;
		color: $primary;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border-radius: 10px;
		border: 3px solid #fff;
	}
	&:hover {
		background-color: transparent;
		color: $primary;
	}
}

.book-btn {
	font-family: $black-ops-one;
	font-size: rem(33px);
	@include media-breakpoint-down(sm) {
		font-size: 1rem;
	}
	letter-spacing: letter-spacing(10);
	color: #fff;
	display: inline-flex;
	align-items: center;
	padding: .25em .5em; 
	background-color: $dark;
	border: em(3px, 33px) solid transparent;
	line-height: 1;
	border: 3px solid #fff;
	&:hover {
		color: $dark;
		background-color: transparent;
		border-color: $dark;
	}
	&:before {
		font-family: $awesome;
		content: $fa-var-calendar;
		margin-right: 15px;
		font-size: 1.25em;
	}
}