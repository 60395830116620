.footer {
	color: #A8A8A8;
	background-color: $gray-900;
	font-size: rem(14px);
	p {
		margin-bottom: .5rem;
	}
	h4 {
		font-family: $black-ops-one;
		font-size: rem(24px);
		margin-bottom: 1em;
	}
	.nav {
		.nav-item {
			.nav-link {
				text-transform: uppercase;
				display: flex;
				color: inherit;
				align-items: baseline;
				font-size: rem(13px);
				word-spacing: 0.02em;
				font-family: $graduate;
				&:hover {
					color: $red;
				}
				&:before {
					font-family: $awesome;
					content: $fa-var-angle-right;
					margin-right: .5em;
					color: $red;
				}
			}
		}
	}
	.address {
		background-color: rgba(#000, .16);
		.nav {
			.nav-item {
				.nav-link {
					&:before {
						display: none;
					}
				}
			}
		}
	}
}

.copyright {
	color: #fff;
	background-color: #9E1418;
	p {
		margin-bottom: 0;
	}
	.fa {
		color: lighten(#9E1418, 25%);
	}
	a {
		color: inherit;
	}
}

.footer {
	.subscribe-form {
		.form-control {
			font-size: rem(14px);
			margin-bottom: .5rem;
		}
	}
}

.subscribe-form {
	.form-group {
		position: relative;
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}
	}
	.form-control {
		font-weight: 700;
	}
	button[type="submit"] {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		color: #fff;
		border: none;
		width: 2.5em;
		&:hover {
			opacity: .75;
			cursor: pointer;
		}
	}
}