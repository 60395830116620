.collapse-block {
	.collapse {
		&-item {
			background-color: #F7F7F7;
			margin-bottom: 25px;
			border: 2px solid #D8D8D8;
			&.active {
				.collapse {
					&-header {
						&:after {
							content: "-";
						}
					}
				}
			}
		}
		&-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-family: $graduate;
			font-size: rem(27px);
			min-height: em(92px, 27px);
			color: $primary;
			//padding-right: em(92px, 27px);
			@media screen and (max-width: 650px) {
				//padding: 15px 40px 15px 15px;
				position: relative;
			}

			&:after {
				display: inline-flex;
				content: '+';
				$w: em(92px, 62px);
				max-height: 100%;
				width: $w;
				height: $w;
				color: #fff;
				background-color: $primary;
				justify-content: center;
				align-items: center;
				font-size: rem(62px);
				font-weight: 600;
				@media screen and (max-width: 650px) {
					position: absolute;
					right: 9px;
					top: calc(50% - #{em(46px, 62px)});
				}
			}
		}
		&-title {
			padding: rem(18px) rem(40px);
		}
		&-body {
			display: none;
			padding:  0 rem(40px) rem(40px);
		}
	}
}