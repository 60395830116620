.slider {
	$breakpoints: (
		'xl': 1200px,
		'md': 900px,
		'sm': 768px,
		'xs': 600px
	);
	position: relative;
	min-height: 160px;
	width: 100%;
	z-index: 1;
	& > * {
		&.init {
			height: 0;
			opacity: 0;
		}
	}

	.main-slider {
		width: 100%;
		position: relative;
	}

	.slick {
		&-arrow {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			z-index: 100;
			font-size: 2.5rem;
			color: $primary;
			@media screen and (max-width: map-get($breakpoints, 'sm')) {
				display: none;
			}
			&:hover {
				color: lighten($primary, 15%);
			}
			$offset: 3%;
			&.slick-prev {
				left: $offset;
			}
			&.slick-next {
				right: $offset;
			}
		}
		&-dots {
			position: absolute;
			width: 100%;
			bottom: 7%;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0 15px;
			@include media-breakpoint-down(sm) {
				bottom: 0%;
			}
			li {
				display: inline-flex;
				justify-content: center;
				align-items: center;
				&:not(:last-child) {
					margin-right: rem(16px);
				}
				&:last-child {
					bottom: 0;
				}
				a {
					@include icon('slider-dot');
					color: #A1A1A1;
					font-size: .75em;
				}
				&.slick-active {
					a {
						@include icon('slider-dot-active');
						color: $primary;
						font-size: 1.5em;
					}
				}
			}
		}
	}

	&-item {
		$z-index-item: -1;
		$z-index-overlay: 1;
		$z-index-description: 5;
		background-size: cover;
		position: relative;
		z-index: $z-index-item;
		padding: rem(40px) 0;
		display: flex!important;
		align-items: center;
		justify-content: center;
		.description {
			display: inline-flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: relative;
		}
		.title-1 {
			font-size: rem(30px);
			font-family: $teko;
			color: #fff;
			text-transform: uppercase;
			line-height: 1;
			text-align: center;
			@include media-breakpoint-down(sm) {
				font-size: 20px;
			}
		}
		.title-2 {
			font-size: rem(66px);
			@include media-breakpoint-down(sm) {
				font-size: 33px;
			}
			font-family: $black-ops-one;
			color: #fff;
			text-transform: uppercase;
			line-height: 1;
			text-align: center;
		}
	}
}