@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin animations($value) {
  -webkit-animation: $value;
  -moz-animation: $value;
  -o-animation: $value;
  animation: $value;
}

@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}

@mixin appearanceCustom($value) {
  -webkit-appearance: $value;
  -moz-appearance: $value;
  -ms-appearance: $value;
  -o-appearance: $value;
  appearance: $value;
  &::-ms-expand {
    display: none;
  }
}

@mixin placeholder-form {
	&::placeholder  {
		@content;
		opacity: 1;
  }
}

@mixin desaturate {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

@mixin clear-link {
  &:link,
  &:visited ,
  &:hover ,
  &:active {
      text-decoration: none;
  }
}

@mixin custom-link($font-color) {
  @include clear-link;
  color : $font-color;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: lighten($font-color, 10%)
  }
}

@mixin backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
}

@mixin responsiveVideo($w, $h) {
	position: relative;
	height: 0;
	padding-bottom: 100% * $h / $w;
	& > * {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

@mixin set-color($colors, $prop: null, $prefix:null) {
	@if $prop {
		$prefix: $prefix + "-";
	}
	@else {
		$prop: color;
	}
	&.#{$prefix}color{
		@each $class, $color in $colors {
			&-#{$class} {
				#{$prop} :$color;
			}
		}
	} 
}