.parties {
  .parties-item {
    .photo {
      .object-fill {
        height: 100%;
        width: 100%;
        overflow: hidden;
        background-position: center;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
        @include media-breakpoint-down(md) {
          display: none;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
    .description {
      @include media-breakpoint-up(lg) {
        padding: 4rem 5rem;
      }
      a {
        margin-left: 2.5rem;
      }
    }
    &:nth-child(even) {
      @include media-breakpoint-up(md) {
        .description {
          order: 1;
          text-align: right;
          a {
            margin-left: 0;
            margin-right: 2.5rem;
          }
        }
        .photo {
          order: 2;
        }
      }
    }
  }
}