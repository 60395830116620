.testimonial-slider {
	
	&.slider {
		.slick {
			&-dots {
				bottom: -5%;
			}
		}
	}
	.testimonial-item {
		height: 100%;
		@include media-breakpoint-down(sm) {
			padding: 2rem 25px 3rem;
		}
		.row {
			@include media-breakpoint-down(sm) {
				flex-direction: column;
			}
		}
		.quote {

		}
		.testimonial-body {
			@include media-breakpoint-down(sm) { 
				order: 2;
			}
			.text {
				display: flex;
				justify-content: flex-end;
				text-align: right;
				font-family: $graduate;
				font-size: rem(27px);
				letter-spacing: letter-spacing(10);
				line-height: (40px / 27px);
				color: $primary;
				margin-bottom: .25em;
				@include media-breakpoint-down(sm) { 
					flex-direction: column;
					text-align: center;
				}
				&:before {
					font-family: $black-ops-one;
					font-size: rem(76px);
					line-height: .3;
					margin-top: 1rem;
					@include media-breakpoint-up(sm) { 
						margin-right: 3em;
						line-height: .8;
						margin-top: 0;
					}
					color: $primary;
					content: "“";
				}

			}
			.author {
				display: flex;
				justify-content: flex-end;
				align-items: baseline;
				text-align: right;
				font-family: $graduate;
				font-size: rem(18px);
				letter-spacing: letter-spacing(10);
				&:before {
					content: "~";
					margin-right: 5px;	
				}
			}
		}
		.aim {
			@include media-breakpoint-down(sm) { 
				order: 1;
			}
			img {
				max-height: rem(244px);
				@include media-breakpoint-down(sm) { 
					max-height: 64px;
					margin: 0 auto;
				}	
			}
		}
	}
}