.navbar {
    background-color: #363636;
    .nav-item {
        &.active {
            position: relative;
            &:after {
                display: block;
                position: absolute;
                left: 0;
                bottom: -.5rem;
                height: 1rem;
                width: 100%;
                background-image: url('#{$images}active-link.png');
                background-size: auto 100%;
                background-position: center;
                background-repeat: no-repeat;
                content: " ";
            }
            .nav-link {
                color: $primary;
            }
        }
    }
    .nav-link {
        white-space: nowrap;
        font-family: $graduate;
        font-size: rem(16px);
        color: #fff;
        transition: color .6s ease;
        &:hover {
            color: $primary;
        }
    }
}

.header-mobile {
    .navbar {
        position: absolute;
        right: 0;
        top: 31;
        width: 50%;
        .nav-item {
            &.active {
                &:after {
                    transform: rotate(180deg);
                }
            }
            
        }
        
    }
}
