.banner {
	h2 {
		margin: 0 0.5em;
		flex: 1 1 auto;
	}
	.links-container {
		flex: 0 0 auto;
	}
	@include media-breakpoint-down(sm) {
		h2 {
			margin-bottom: 1rem;
		}
		.links-container {
			flex: 1 1 100%;
			a {
				width: 100%;
				margin-bottom: 0.5rem;
			}
		}
	}
}

.ready-from-mi-combat {
	position: relative;
	background-size: 100% auto;
	background-position: left;
	.schedule {
		margin: 0 auto;
		@include media-breakpoint-up(sm) {
			width: 85%;
			margin-bottom: 6rem;
		}
		font-family: $graduate;
		.photo {
			//flex: 0 1 auto;
		}
		.description {
			//flex: 1 1 auto;
			dt,
			dd {
				font-weight: weight("Regular");
				line-height: 1.2;
				margin-bottom: 0.6em;
			}
		}
		p {
			color: #b7b7b7;
			font-size: rem(17px);
			margin-bottom: 2rem;
			font-family: $quantico;
		}
		.btn {
			width: em(146px, 14px);
		}
	}
	.gun-man {
		position: absolute;
		bottom: 0;
		left: rem(190px);
		width: rem(749px);
		height: rem(683px);
		background-image: url(#{$images}gun-man.png);
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		@include media-breakpoint-down(md) {
			display: none;
		}
	}
}

.feature-card {
	@include block-decoration(-0.3em);
	height: rem(446px);
	max-width: rem(351px);
	background-size: cover;
	background-position: center;
	display: block;
	margin: 0 auto;
	&:hover {
		.feature-card-body {
			height: 100%;
			background-color: rgba(#3b3e43, 0.84);
			justify-content: center;
			.title {
				color: $primary;
				@include icon("aim");
				&:before {
					color: gray;
					display: block;
					text-align: center;
					font-size: rem(146px);
				}
			}
			.link {
				display: inline-block;
			}
		}
	}
	.feature-card-body {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: $gray;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-start;
		.title {
			font-family: $black-ops-one;
			font-size: rem(45px);
			color: #3b3e43;
			line-height: 1;
		}
		.link {
			font-family: $graduate;
			padding: 0.2em 0;
			border-top: 1px solid #fff;
			border-bottom: 1px solid #fff;
			color: #fff;
			margin-top: 0.5em;
			display: none;
		}
	}
}

.welcome-red-down {
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		@include media-breakpoint-down(md) {
			position: absolute;
			top: 0;
			left: 0;
		}
	}
	.description {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		@include media-breakpoint-down(md) {
			position: relative;
		}
		h2 {
			font-size: rem(40px);
			text-align: center;
			color: $red;
			span {
				font-size: percentage(65px / 40px);
				display: block;
			}
		}
		p {
			font-size: 17px;
			line-height: (24 / 17);
		}
		.btn {
			width: 35%;
		}
	}
}

.irsmg {
	h2 {
		font-size: rem(65px);
		line-height: 1;
	}
	.img-wrapper {
		position: relative;
		display: inline-block;
		.point-group {
			position: absolute;
			&:hover {
				.point {
					&:before {
						color: lighten(gray, 15%);
					}
				}
				.label {
					display: block;
				}
			}
			.point {
				cursor: pointer;
				@include media-breakpoint-down(sm) {
					display: none;
				}
				@include icon("aim");
				&:before {
					color: gray;
					display: block;
					text-align: center;
					font-size: rem(84px);
					line-height: 1;
				}
			}
			.label {
				width: 23em;
				position: absolute;
				font-size: responsive-size(8px, 16px, 760px, 1920px);
				max-width: 430px;
				display: none;
				background-color: #959595;
				z-index: 10;
				padding: 1rem;
				$list: (
					top: bottom,
					bottom: top,
					left: right,
					right: left,
				);
				@each $class, $prop in $list {
					&.#{$class} {
						#{$prop}: -#{rem(84px)};
					}
				}
			}
		}
	}
	p {
		font-size: rem(17px);
		line-height: (24px / 17px);
	}
}

.our-rates {
	.margin {
		margin-top: 6rem;
	}
	dt,
	dd {
		font-family: $graduate;
		font-size: rem(24px);
		line-height: 1.2;
		margin-bottom: 0.6em;
		letter-spacing: letter-spacing(10);
	}
}

.subscribe {
	h2 {
		font-family: $graduate;
		font-size: rem(23px);
		text-transform: uppercase;
		span {
			font-family: $quantico;
			font-size: percentage(19px / 23px);
			text-transform: lowercase;
			display: block;
		}
	}
	p {
		font-family: $quantico;
		font-size: rem(13px);
		margin: 0;
	}
}

.without-info {
	@include media-breakpoint-down(md) {
		img {
			position: static !important;
		}
	}
}
