@font-face {
	font-family: "HadroutIcons";
	src: url('../fonts/HadroutIcons.eot');
	src: url('../fonts/HadroutIcons.eot?#iefix') format('eot'),
		url('../fonts/HadroutIcons.woff2') format('woff2'),
		url('../fonts/HadroutIcons.woff') format('woff'),
		url('../fonts/HadroutIcons.ttf') format('truetype'),
		url('../fonts/HadroutIcons.svg#HadroutIcons') format('svg');
}

@mixin icon-styles {
	font-family: "HadroutIcons";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	// speak: none; // only necessary if not using the private unicode range (firstGlyph option)
	text-decoration: none;
	text-transform: none;
}

%icon {
	@include icon-styles;
}

@function icon-char($filename) {
	$char: "";

	@if $filename == aim {
		$char: "\E001";
	}
	@if $filename == btn-decoration {
		$char: "\E002";
	}
	@if $filename == slider-arrow-next {
		$char: "\E003";
	}
	@if $filename == slider-arrow-prev {
		$char: "\E004";
	}
	@if $filename == slider-dot-active {
		$char: "\E005";
	}
	@if $filename == slider-dot {
		$char: "\E006";
	}
	@if $filename == title-decoration {
		$char: "\E007";
	}

	@return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
	&:#{$insert} {
		@if $extend {
			@include icon-styles;
		} @else {
			@include icon-styles;
		}
		content: icon-char($filename);
	}
}

.icon-aim {
	@include icon(aim);
}
.icon-btn-decoration {
	@include icon(btn-decoration);
}
.icon-slider-arrow-next {
	@include icon(slider-arrow-next);
}
.icon-slider-arrow-prev {
	@include icon(slider-arrow-prev);
}
.icon-slider-dot-active {
	@include icon(slider-dot-active);
}
.icon-slider-dot {
	@include icon(slider-dot);
}
.icon-title-decoration {
	@include icon(title-decoration);
}
