@import "typography";
@import "../components/icons";

%base-text {
    font-size: 1rem;
    font-family: $quantico;
}

%section-title {
    font-family: $black-ops-one;
	font-size: rem(30px);
	@include media-breakpoint-down(sm) {
		font-size: 14px;
	}
}

@mixin block-decoration($offset: 0, $color: $primary, $fs: 1rem) {
	position: relative;
	
	&:after,
	&:before  {
		position: absolute;
		font-family: "HadroutIcons";
		font-weight: bold;
		content: icon-char('btn-decoration');
		color: $color;
		z-index: 100;
		font-size: $fs;
		width: 1.5em;
		height: 1.5em;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		top: $offset;
	}
	&:before {
		left: $offset;
		transform: rotate(90deg);
	}
	&:after {
		right: $offset;
		transform: rotate(180deg);
	}
	& > *:first-child {
        &:after,
        &:before  {
			position: absolute;
			font-family: "HadroutIcons";
			font-weight: bold;
			content: icon-char('btn-decoration');
			color: $color;
			z-index: 100;
			font-size: $fs;
			width: 1.5em;
			height: 1.5em;
			display: inline-flex;
			justify-content: center;
			align-items: center;
            bottom: $offset;
        }
        &:before {
            left: $offset;
        }
        &:after {
            right: $offset;
            transform: rotate(270deg);
        }
	}
}

img {
    max-width: 100%;
}

html {
    @media screen and (min-width: 1921px) {
        font-size: 20px;
    }
    @media screen and (min-width: 1201px) and (max-width: 1920px) {
        font-size: responsive-size(16px, 20px, 1200px, 1920px);
    }
    @include media-breakpoint-between(sm, lg) {
        font-size: responsive-size(14px, 16px, 1200px, 1920px);
    }
    font-size: 14px;
    font-family: $quantico;
}

body {
	background-color: $gray;
}

.text-block {
    p, li {
        @extend %base-text;
	}
	li {
		display: flex;
		align-items: flex-start;
		line-height: 1.2;
		margin-bottom: .5em;
		&:before {
			font-family: $awesome;
			content: $fa-var-circle;
			font-size: .5em;
			margin-right: 5px;
			line-height: 3;
		}
	}
	table {
		tr {
			th, td {
				padding: .5rem 1rem
			}
		}
	}
}

main {
    min-height: 40vh;
}

.section-title {
	@extend %section-title;
	display: inline-block;
    position: relative;
	$offset: -2.15em;
	@include media-breakpoint-down(sm) {
		max-width: 75%;
	}
	&:after,
	&:before {
		position: absolute;
		font-size: 1em;
		font-family: "HadroutIcons";
		font-weight: bold;
		content: icon-char('title-decoration');
		display: inline-block;
		color: $primary;
	}
	&:before {
		left: $offset;
		top: -.25em
	}
	&:after {
		transform: rotate(180deg);
		right: $offset;
		bottom: -.25em;
	}
	&.decoration-white {
		&:after,
		&:before {
			color: #fff;
		}
	}
}

.section {
	background-size: cover;
	$pd: 4rem;
	&.decoration {
		$colors: (
			'white' : #fff, 
			'primary': $primary
		);
		
		@each $class, $color in $colors {
			&-#{$class} {
				@include media-breakpoint-up(sm) {
					@include block-decoration(($pd/2), $color, 2rem);
				}
				&.mobile-decoration {
					@include media-breakpoint-down(sm) {
						@include block-decoration(($pd/2), $color, 2rem);
					}	
				}
			}
		}
	}
}

h2 {
	@extend %section-title;
	color: $primary;
}

h3 {
	font-family: $graduate;
	font-size: rem(26px);
	color: $primary;
}

h4 {

}

h5 {
	
}

a[name^="eztoc"] {
	display: none;
}