.call-to-book {
  &.bg-image {
    background-image: url("#{$images}AIM.jpg");
  }
  background-color: #000;
  .link {
    font-family: $black-ops-one;
    font-size: rem(65px);
    color: $red;
    &:hover {
      color: lighten($red, 15%);
    }
    @include media-breakpoint-down(sm) {
      font-size: 1.65rem;
    }
  }
}