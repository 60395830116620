.rank-item {
  height: 100%;
  display: flex;
  flex-direction: column;
  .body {
    padding: 1rem 2rem 3.5rem;
    flex: 1 0 auto;
    .title,
    .price {
      font-family: $black-ops-one;
      font-size: rem(47px);
      color: #fff;
    }
    .price {
      color: #E0E0DF;
      &:before {
        content: " ";
        display: block;
        margin: 0.5rem auto;
        background-color: #fff;
        width: rem(63px);
        height: rem(4px);
      }
    }
    .description {
      p {
        margin-bottom: .75em;
        line-height: 1.1;
        font-size: rem(14px);
        color: #E2E2E2;
        font-family: $graduate;
        text-transform: uppercase;
      }
    }
  }
  .footer {
    a {
      font-family: $black-ops-one;
      font-size: rem(30px);
      color: #fff;
      background-color: #3B3E43;
      padding: .25em;
    }
  }
}