.header {
    position: relative;
    z-index: 5;
    .logo {
        position: absolute;
        display: flex;
        top: 5%;
        height: 115%;
        align-items: center;
        justify-content: center;
        z-index: 10;
        @include media-breakpoint-up(sm) {
            left: calc( 50% );
            transform: translateX(-50%);
        }
        @include media-breakpoint-down(sm) {
            left: 5%;
        }
        img {
            height: 100%;
        }
    }
}

.header-top {

}

.header-middle {
    .header-links {
        li {
            @include media-breakpoint-up(lg) {
                //margin-left: 6rem;
            }
            a {
                color: #fff;
                transition: color .6s ease;
                &:hover {
                    color: $primary;
                }
                .fa {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    $color: $primary;
                    color: $color;
                    border: 1px solid $color;
                    font-size: 1.2em;
                    $w: 1.5em;
                    width: $w;
                    height: $w;
                    margin-right: .5em;
                }
            }
        }
    }
}

.header-mobile {
    .header-top {
        text-align: right;
        font-size: 9px;
        padding: 1em .5em;
        #collapse-menu {
            background-color: transparent;
            border: none;
            font-size: 20px;
            vertical-align: middle;
        }
    }
    .header-bottom {
        text-align: right;
        font-size: 14px;
        padding: 1.5em .5em;
    }
}