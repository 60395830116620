.form {
	.form-group {
		label {
			font-size: rem(10px);
			background-color: $primary;
			color: #fff;
			padding: .75rem .5rem;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			margin: 0;
		}
		.form-control {
			font-size: rem(17px);
			padding: .5em .75em;
			color: #3B3E43;
			resize: none;
			&.is-invalid {
				background-color: mix(#f00, #fff, 7%);
			}
		}
	}
}