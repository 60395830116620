.news-and-promotions {
	.container {
		position: relative;
	}
	.news-item {
		position: relative;
		color: #fff;
		&.on-page {
			color: #3B3B3B;
			p {
				font-weight: weight('Bold');
				line-height: (28px / 20px);
				letter-spacing: letter-spacing(10);
				font-size: 1rem;
			}
		}
		a.date {
			font-family: $black-ops-one;
			font-size: rem(19px);
			&.on-page {
				font-size: rem(59px);
				color: #fff;
				line-height: 1.2;
				left: 0;
				$w: em(160px, 60px);
				width: $w;
				height: $w;
			}
			color: inherit;
			$w: em(63.5px, 19px);
			width: $w;
			height: $w;
			background-color: $primary;
			position: absolute;
			left: 15px;
			top: 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			z-index: 1;
			span {
				font-family: $graduate;
				font-size: em(13px, 19px);
			}
			&:hover {
				background-color: #fff;
				color: $primary;
			}
		}
		a.title {
			color: inherit;
			font-family: $black-ops-one;
			font-size: rem(21px);
			&.on-page {
				font-size: rem(37px);
				color: #3B3B3B;
				text-transform: uppercase;
				&:hover {
					color: lighten($primary, 15%);
				}
			}
			line-height: 1.3;
			margin-top: 1em;
			margin-bottom: .5em;
			&:hover {
				color: $primary;
			}
		}
		a.read-more {
			font-family: $graduate;
			font-size: rem(13px);
			&.on-page {
				border-top-width: 9px;
				border-top-color: $primary;
				font-size: rem(29px);
				color: $primary;
				&:hover {
					color: lighten($primary, 15%);
					border-top-color: lighten($primary, 15%);
				}
			}
			padding: .5em 0;
			border-top: 4px solid transparent;
			color: #fff;
			&:hover {
				color: $primary;
				border-top-color: $primary;
			}
		}
		p {
			font-family: $quantico;
			font-size: rem(15px);
			line-height: ( 24 / 15 );
		}
	}
}

.all-news {
	@include media-breakpoint-up(lg) {
		position: absolute;
		right: 0;
		top: 0;
		font-family: $graduate;
		font-size: rem(27px);
		display: inline-flex;
		align-items: center;
		color: #fff;
		@include icon(slider-arrow-next, $insert: after);
		&:after {
			color: $red;
			margin-left: 5px;
		}
		&:hover {
			color: $red;
		}
	}
}