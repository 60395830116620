.location-links {
	display: flex;
	flex-direction: column;
	a {
		display: flex;
		align-items: baseline;
		color: $primary;
		&:hover {
			color: lighten($primary, 15%);
		}
		&:not(:last-child) {
			margin-bottom: .5rem;
		}
		&:before {
			font-family: $awesome;
			$w: 1.8em;
			width: $w;
			height: $w;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			font-size: rem(22px);
			border: 1px solid $primary;
			margin-right: .5em;
		}
		&.location {
			font-family: $graduate;
			font-size: rem(27px);
			line-height: 1;
			&:before {
				content: $fa-var-map-marker;
			}
		}
		&.tel {
			font-family: $black-ops-one;
			font-size: rem(41px);
			&:before {
				content: $fa-var-phone;
			}
		}
	}
}

#map {
	min-height: 350px;
	height: 100%;
}