.quick-links {
	display: flex;
	align-items: baseline;
	justify-content: space-around;
	@include media-breakpoint-down(sm) {
		flex-wrap: wrap;
	}
	.quick-link {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: rem(27px);
		text-transform: uppercase;
		color: #fff;
		margin: 0 1rem;
		width: auto;
		text-align: center;
		@include media-breakpoint-between(sm, md) {
			width: (100% / 3);
		}
		@include media-breakpoint-down(sm) {
			width: 50%;
			margin: 0 auto 1rem;
		}
		img {
			height: rem(72px);
		}
		&:hover {
			img {
				transform: scale(1.2);
				color: mix($primary, #fff, 8%);
			}
			span {
				&:before {
					background-color: mix($primary, #fff, 8%);
				}
			}
		}
		span {
			white-space: nowrap;
			font-size: 100%;
			font-family: $graduate;
			@include media-breakpoint-down(sm) {
				white-space: normal;
			}
			&:before {
				display: block;
				height: rem(4px);
				background-color: #fff;
				width: rem(72px);
				content: " ";
				margin: rem(15px) auto rem(10px);
			}
		}
	}
}