.pagination {
	display: flex;
	justify-content: space-between;
	align-items: center;
	&-item {
		margin: 0 5px;
		&.active,
		&:hover {
			.pagination-link {
				background-color: $dark;
			}
		}
		&.disabled {
			pointer-events: none;
			opacity: .75;
		}
	}
	&-link {
		font-size: inherit;
		color: inherit;
		height: 100%;
		width: 100%;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		background-color: $primary;
		font-family: $graduate;
		$color: #fff;
		$font-size: 27px;
		color:  $color;
		font-size: $font-size;
		$w: em(69px, $font-size);
		width: $w;
		height: $w;
		&.prev,
		&.next {
			color: $primary;
			background-color: #F1F9FF;
			border: 1px solid #BCE0FD;
			font-size: rem(16px);
			border-radius: em(10px, 16px);
			$w: em(50px, 16px);
			width: $w;
			height: $w;
		}
	}
}