$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
	xl: 1200px,
	hd: 1440px
);

@function bp($name) {
  @return map-get($grid-breakpoints, $name);
}

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
	xl: 1140px,
	hd: 1390px
);

$font-family-base:            'Quantico', sans-serif;

$link-hover-decoration:     none;

$border-radius:               0;
$border-radius-hd:            0;
$border-radius-lg:            0;
$border-radius-sm:            0;

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #363636 !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$grays: () !default;
$grays: map-merge((
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
), $grays);

$old-brick:     #8e1f27;
$gray:    #e1e1e1;
$red: #BC1B20;

$blue:    #007bff;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;

$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #28a745;
$teal:    #20c997;
$cyan:    #17a2b8;

$colors: ();
$colors: map-merge((
  "red": $red,
  "black": $black
), $colors);

$primary:       $old-brick;
$secondary:     $gray;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          #3B3E43;

$theme-colors: ();
$theme-colors: map-merge((
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark,
  "black":      $black,
  "red":        $red,
  "gray":       $gray
), $theme-colors);

$component-active-bg:         theme-color("secondary") !default;