.mission-link {
  position: relative;
  display: inline-block;
  margin: auto;
  //min-width: 19.05rem;
  img {
    width: 100%;
  }
  @media screen and (min-width: (bp(hd) + 1px)) {
    width: 20%;
  }
  @media screen and (min-width: (bp(lg) + 1px)) and (max-width: bp(hd)) {
    width: 25%;
  }
  @media screen and (min-width: (bp(sm) + 1px)) and (max-width: bp(lg)) {
    width: 50%;
  }
  &:hover {
    .description {
      opacity: 1;
    }
  }
  .description {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all .6s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    @include icon('aim');
    background-color: rgba(#9F0000, .75);
    &:before {
      color: gray;
      display: block;
      text-align: center;
      font-size: rem(146px);
      line-height: 1;
    }
    .title {
      font-family: $black-ops-one;
      font-size: rem(37px);
      color: #fff;
      line-height: 1;
    }
    .link {
      font-family: $graduate;
      font-size: rem(13px);
      color: #fff;
      padding: .25em 0;
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
      margin: .5rem 0;
    }

  }
}

.mission-hr {
  padding: 2rem 2rem 5rem;
  background-color: $black;
  background-image: url("#{$images}AIM.jpg");
  background-repeat: no-repeat;
}