.the-story {
	background-image: url("#{$images}AIM.jpg");
	background-color: $black;
}

.incounter-items {
	justify-content: space-between;
	.item {
		background-image: url("#{$images}feature-bg.png");
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		$w: em(142px, 14px);
		background-size: cover;
		background-position: center;
		width: $w;
		height: $w;
		font-size: rem(14px);
		text-transform: uppercase;
		.incounter {
			font-size: rem(20px);
		}
	}
}