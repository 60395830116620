.socials {
	&-header {
		li {
			padding: .25rem;
			&:not(:last-child) {
				margin-right: .5rem;
			}
			a {
				color: #fff;
				&:hover {
					color: darken(#fff, 15%);
				}
			}
		}
	}
	&-footer {
		li {
			&:not(:last-child) {
				margin-right: 5px;
			}
			a {
				color: #252525;
				$color-list: (
					'facebook': #3A5897,
					'twitter': #00AEEF, 
					'instagram': #998675,
					'pinterest-p': #FF0000,
					'linkedin': #0054A6,
					'google-plus': #8E1F27,
					'youtube': #9E0B0F,
				);
				font-size: rem( 35px );
				display: inline-flex;
				justify-content: center;
				align-items: center;
				$w: 1.5em;
				width: $w;
				height: $w;
				@each $cls, $color in $color-list {
					&.fa-#{$cls} {
						background-color: $color;
						border: 2px solid $color;
						transition: all .6s ease;
						&:hover {
							color: $color;
							background-color: transparent;
						}
					}
				}
			}
		}
	}
}