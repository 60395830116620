@mixin loader-rotate {
	 @keyframes loader-rotate{
		0% {
			@include transform (rotate(0));
		}

		100% {
			@include transform (rotate(360deg));
		}
	}
	@-webkit-keyframes loader-rotate {
		0% {
			@include transform (rotate(0));
		}

		100% {
			@include transform (rotate(360deg));
		}
	}
}
@mixin loader-scale {
	 @keyframes loader-scale{
		0% {
			opacity: 0;
			@include transform (scale(0));
		}

		50% {
			opacity: 1;
		}

		100% {
			opacity: 0;
			@include transform (scale(1));
		}
	}
}
