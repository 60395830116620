.news-item {
	.socials-footer {
		position: relative;
		&:before {
			position: absolute;
			top: 50%;
			left: 0;
			content: " ";
			height: 2px;
			background-color: #a8a8a8;
			width: 65%;
			display: block;
			z-index: 0;
		}
		li {
			background-color: $gray;
			z-index: 1;
			position: relative;
			&.title {
				padding: .5em 1.5em;
				font-family: $graduate;
				font-size: rem(16px);
			}
			a {
				color: $gray;
			}
		}
	}
}

.news-and-promotions {
	a.arrow-artc {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		$offset: -180%;
		&:hover:not(.disabled) {
			color: #fff;
			&:before {
				color: #fff;
			}
		}
		&:before {
			height: 3em;
			width: 3em;
			font-family: $awesome;
			background-color: #969696;
			color: $primary;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			border-radius: 10px;
			margin-top: 1.5em;
			border: 3px solid #fff;
			margin-bottom: rem(21px);
		}
		&.prev {
			@media screen and (min-width: 1600px) {
				margin-left: $offset; 
			}
			&:before {
				content: $fa-var-arrow-left;
			}
		}
		&.next {
			@media screen and (min-width: 1600px) {
				margin-right: $offset; 
			}
			&:before {
				content: $fa-var-arrow-right;
			}
		}
	}
	.archives {
		h4 {
			font-family: $black-ops-one;
			color: #3B3E43;
			margin: 0 1rem 1.5rem;
			span {
				border-bottom: 3px solid $primary;
			}
		}
		.nav-link {
			padding-top: .25em;
			padding-bottom: .25em;
			font-family: $graduate;
			&.active {
				color: #000000;
				text-decoration: underline;
			}
		}
	}
}