$weights: (
		'Thin': 100,
		'Hairline': 100,
		'ExtraLight': 200,
		'UltraLight': 200,
    'Light': 300,
		'Normal': 400,
		'Regular': 400,
    'Medium': 500,
		'SemiBold': 600,
		'DemiBold': 600,
    'Bold': 700,
		'ExtraBold': 800,
		'UltraBold': 800,
		'Black': 900,
		'Heavy': 900,
);

@function weight($name) {
	@return map-get($weights, $name);
}

@mixin add-google-font($fonts) {
	$string: null;
	@each $font in $fonts {
		@if($string) {
			$string: $string + '|';
		}
		@else {
			$string: 'family=';
		}
		$string: $string + map-get($font, 'name');
		@if map-has-key($font, 'size') {
			$string: $string + ':';
			@each $size in map-get($font, 'size') {
				@debug $size;
				$string: $string + weight($size) + ",";
			}
		}
	}
	@import url('https://fonts.googleapis.com/css?#{$string}');
}

$fonts: (
	(
		'name': 'Black+Ops+One',
		'size': 'Regular'
	),
	(
		'name': 'Graduate',
		'size': 'Regular'
	),
	(
		'name': 'Quantico',
		'size': ('Bold', 'Regular')
	),
	(
		'name': 'Raleway',
		'size': 'Black'
	),
	(
		'name': 'Teko',
		'size': 'Medium'
	),
);

@include add-google-font($fonts);
@import "font-awesome";

$graduate: 'Graduate', cursive;
$quantico: 'Quantico', sans-serif;
$raleway: 'Raleway', sans-serif;
$teko: 'Teko', sans-serif;
$black-ops-one: 'Black Ops One', cursive;

$barlow-condensed: 'Barlow Condensed', sans-serif;
$pt-sans: 'PT Sans', sans-serif;
$oswald: 'Oswald', sans-serif;
$lato: 'Lato', sans-serif;
$source-sans-pro: 'Source Sans Pro', sans-serif;
$open-sans: 'Open Sans', sans-serif;
$roboto: 'Roboto', sans-serif;
$awesome: 'FontAwesome', sans-serif;
$montserrat : 'Montserrat', sans-serif;
$courgette: 'Courgette', cursive;
$poppins: 'Poppins', sans-serif;
$lora: 'Lora', serif;